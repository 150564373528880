import { SvgIcon } from '@mui/material';

function LinkIcon() {
  return (
    <SvgIcon
      role="img"
      viewBox="0 0 12 13"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: '12px',
        height: '13px',
        cursor: 'pointer',
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.80002 7.06002C8.80002 6.75077 9.05077 6.50002 9.36002 6.50002C9.66927 6.50002 9.92002 6.75077 9.92002 7.06002V10.42C9.92002 11.3479 9.1679 12.1 8.24002 12.1H2.08002C1.15215 12.1 0.400024 11.3479 0.400024 10.42V4.26002C0.400024 3.33215 1.15215 2.58002 2.08002 2.58002H5.44002C5.74928 2.58002 6.00002 2.83077 6.00002 3.14002C6.00002 3.44927 5.74928 3.70002 5.44002 3.70002H2.08002C1.77077 3.70002 1.52002 3.95077 1.52002 4.26002V10.42C1.52002 10.7293 1.77077 10.98 2.08002 10.98H8.24002C8.54928 10.98 8.80002 10.7293 8.80002 10.42V7.06002Z"
        fill="#787883"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.4803 2.01999H7.6803C7.37105 2.01999 7.1203 1.76925 7.1203 1.45999C7.1203 1.15074 7.37105 0.899994 7.6803 0.899994H11.0403C11.3496 0.899994 11.6003 1.15074 11.6003 1.45999V4.81999C11.6003 5.12925 11.3496 5.37999 11.0403 5.37999C10.731 5.37999 10.4803 5.12925 10.4803 4.81999V2.01999Z"
        fill="#787883"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.27616 8.01618C5.05741 8.2348 4.70291 8.2348 4.48416 8.01618C4.26553 7.79743 4.26553 7.44293 4.48416 7.22418L10.6442 1.06418C10.8629 0.845552 11.2174 0.845552 11.4362 1.06418C11.6548 1.28292 11.6548 1.63742 11.4362 1.85618L5.27616 8.01618Z"
        fill="#787883"
      />
    </SvgIcon>
  );
}

export default LinkIcon;
