import { SvgIcon, useTheme } from '@mui/material';

function BurgerMenuIcon() {
  const { palette } = useTheme();

  return (
    <SvgIcon
      role="img"
      viewBox="0 0 24 24"
      sx={{
        fontSize: 34,
        p: '8px',
        '&:hover': {
          color: palette.primary.main,
          textShadow: `${palette.primary.main} 0rem 0rem 0.125rem`,
        },
      }}
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>Burger Menu</title>
      <path
        d="M0.800049 2.50002C0.800049 2.07568 0.96862 1.66871 1.26868 1.36865C1.56874 1.0686 1.9757 0.900024 2.40005 0.900024H21.6C22.0244 0.900024 22.4314 1.0686 22.7314 1.36865C23.0315 1.66871 23.2001 2.07568 23.2001 2.50002C23.2001 2.92437 23.0315 3.33134 22.7314 3.6314C22.4314 3.93145 22.0244 4.10002 21.6 4.10002H2.40005C1.9757 4.10002 1.56874 3.93145 1.26868 3.6314C0.96862 3.33134 0.800049 2.92437 0.800049 2.50002ZM0.800049 10.5C0.800049 10.0757 0.96862 9.66871 1.26868 9.36865C1.56874 9.0686 1.9757 8.90002 2.40005 8.90002H21.6C22.0244 8.90002 22.4314 9.0686 22.7314 9.36865C23.0315 9.66871 23.2001 10.0757 23.2001 10.5C23.2001 10.9244 23.0315 11.3313 22.7314 11.6314C22.4314 11.9315 22.0244 12.1 21.6 12.1H2.40005C1.9757 12.1 1.56874 11.9315 1.26868 11.6314C0.96862 11.3313 0.800049 10.9244 0.800049 10.5ZM0.800049 18.5C0.800049 18.0757 0.96862 17.6687 1.26868 17.3687C1.56874 17.0686 1.9757 16.9 2.40005 16.9H12C12.4244 16.9 12.8314 17.0686 13.1314 17.3687C13.4315 17.6687 13.6 18.0757 13.6 18.5C13.6 18.9244 13.4315 19.3313 13.1314 19.6314C12.8314 19.9315 12.4244 20.1 12 20.1H2.40005C1.9757 20.1 1.56874 19.9315 1.26868 19.6314C0.96862 19.3313 0.800049 18.9244 0.800049 18.5Z"
        fill="#9CA3AF"
      />
    </SvgIcon>
  );
}

export default BurgerMenuIcon;
